<template>
  <div
    role="dialog"
    ref="trap-container"
    tabindex="-1"
    :class="['side-pannel', { showing: show }]"
    :aira-labelledby="`${uid}-header`"
    :hidden="!show"
    :id="`${uid}-container`"
  >
    <h1 :id="`${uid}-header`">Re-upload Codebook File</h1>
    <form
      class="form dataset-uploader__form dataset__section-group"
      @submit.prevent
    >
      <label id="dataset-file-reupload-label" for="dataset-file-reupload">
        <VisibleText>Codebook File</VisibleText>
      </label>
      <input
        type="file"
        id="dataset-file-reupload"
        ref="filereupload"
        accept=".xls, .xlsx"
        @change="onFileReuploadChange"
      />

      <label
        for="dataset-file-sheet-reupload"
        id="dataset-file-sheet-reupload-label"
        class="dataset-file-sheet__label"
      >
        <VisibleText>Select Codebook Sheet</VisibleText>
        <p class="error-msg__inline dataset-file-sheet__err" role="alert">
          {{ errMsg }}
        </p>
        <div class="select-wrapper dataset-file-sheet__select">
          <select
            class="select"
            id="dataset-file-sheet-reupload"
            :aria-invalid="sheets && !selectedCodebookSheet"
            v-model="selectedCodebookSheet"
            @change="onSelectCodebookSheetChange"
          >
            <option
              v-for="(sheet, index) in sheets"
              :key="`codebook-sheet-reupload-options-${index}`"
            >
              {{ sheet }}
            </option>
          </select>
        </div>
      </label>
      <button
        type="submit"
        class="side-pannel__primary-btn"
        @click="reuploadCodebook"
      >
        <VisibleText>Upload Codebook</VisibleText>
      </button>
      <button type="button" class="side-pannel__close-btn" @click="close()">
        close
      </button>
    </form>
  </div>
</template>

<script>
import DatasetMixin from "@/utils/mixins/datasetMixin"
import KeyboardTrapMixin from "@/utils/mixins/keyboardTrapMixin.js"

import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "DatasetUploaderReupload",
  mixins: [DatasetMixin, KeyboardTrapMixin],
  props: {
    uid: {
      default: () => "reupload",
      type: String
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      errMsg: null,
      selectedCodebookSheet: null,
      sheets: null
    }
  },
  methods: {
    onSelectCodebookSheetChange() {
      if (this.selectedCodebookSheet) this.errMsg = null
    },
    close() {
      this.$emit("close-reupload")
    },
    async onFileReuploadChange() {
      let formData = new FormData()
      formData.append("file", this.$refs.filereupload.files[0])
      formData.append("name", this.datasetProject.name)

      try {
        const response = await this.DATASETS_SERVICE.upload(formData)
        this.sheets = response.data.sheet_names
      } catch (e) {
        alert("There was an error uploading the file. Please try again")
        throw new Error("DatasetUploaderReupload " + e.message)
      }
    },
    async reuploadCodebook() {
      if (this.sheets && !this.selectedCodebookSheet) {
        this.errMsg =
          "We noticed you uploaded a file with multiple sheets.\
          Please select a codebook sheet before continuing"
        return
      }
      const response = await this.editDataset({
        sheet: this.selectedCodebookSheet,
        file: this.$refs.filereupload.files[0]
      })
      this.$emit("codebook-reuploaded", response)
    }
  }
}
</script>
